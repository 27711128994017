define("discourse/plugins/discourse-adplugin/discourse/adplugin-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    resource: "admin.adminPlugins",
    path: "/plugins",
    map: function map() {
      this.route("houseAds", {
        path: "/pluginad/house_creatives"
      }, function () {
        this.route("index", {
          path: "/"
        });
        this.route("show", {
          path: "/:ad_id"
        });
      });
    }
  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/components/ad-component", ["exports", "@ember/component", "@ember/service", "@ember/object/computed", "discourse-common/utils/decorators", "discourse/plugins/discourse-adplugin/discourse/helpers/slot-position"], function (_exports, _component, _service, _computed, _decorators, _slotPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend((_dec = (0, _decorators.default)("router.currentRoute.attributes.__type", "router.currentRoute.attributes.id"), _dec2 = (0, _decorators.default)("router.currentRoute.parent.attributes.archetype"), _dec3 = (0, _decorators.default)("currentUser.groups"), _dec4 = (0, _decorators.default)("currentCategoryId", "topicTagsDisableAds", "topicListTag", "isPersonalMessage", "isRestrictedCategory"), (_obj = {
    router: (0, _service.inject)(),
    currentCategoryId: (0, _computed.or)("router.currentRoute.attributes.category.id", "router.currentRoute.parent.attributes.category_id"),
    currentCategorySlug: (0, _computed.or)("router.currentRoute.attributes.category.slug", "router.currentRoute.parent.attributes.category.slug"),
    // Server needs to compute this in case hidden tags are being used.
    topicTagsDisableAds: (0, _computed.alias)("router.currentRoute.parent.attributes.tags_disable_ads"),
    isRestrictedCategory: (0, _computed.or)("router.currentRoute.attributes.category.read_restricted", "router.currentRoute.parent.attributes.category.read_restricted"),
    topicListTag: function topicListTag(type, tag) {
      if (type === "tag" && tag) {
        return tag;
      }
    },
    isPersonalMessage: function isPersonalMessage(topicType) {
      return topicType === "private_message";
    },
    showToGroups: function showToGroups(groups) {
      var currentUser = this.currentUser;

      if (!currentUser || !groups || !this.siteSettings.no_ads_for_groups || this.siteSettings.no_ads_for_groups.length === 0) {
        return true;
      }

      var noAdsGroups = this.siteSettings.no_ads_for_groups.split("|").filter(Boolean);
      var currentGroups = groups.map(function (g) {
        return g.id.toString();
      });
      return !currentGroups.any(function (g) {
        return noAdsGroups.includes(g);
      });
    },
    showOnCurrentPage: function showOnCurrentPage(categoryId, topicTagsDisableAds, topicListTag, isPersonalMessage, isRestrictedCategory) {
      return !topicTagsDisableAds && (!categoryId || !this.siteSettings.no_ads_for_categories || !this.siteSettings.no_ads_for_categories.split("|").includes(categoryId.toString())) && (!topicListTag || !this.siteSettings.no_ads_for_tags || !this.siteSettings.no_ads_for_tags.split("|").includes(topicListTag)) && (!isPersonalMessage || !this.siteSettings.no_ads_for_personal_messages) && (!isRestrictedCategory || !this.siteSettings.no_ads_for_restricted_categories);
    },
    isNthPost: function isNthPost(n) {
      return (0, _slotPosition.isNthPost)(n, this.get("postNumber"));
    },
    isNthTopicListItem: function isNthTopicListItem(n) {
      return (0, _slotPosition.isNthTopicListItem)(n, this.get("indexNumber"));
    }
  }, (_applyDecoratedDescriptor(_obj, "topicListTag", [_dec], Object.getOwnPropertyDescriptor(_obj, "topicListTag"), _obj), _applyDecoratedDescriptor(_obj, "isPersonalMessage", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isPersonalMessage"), _obj), _applyDecoratedDescriptor(_obj, "showToGroups", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showToGroups"), _obj), _applyDecoratedDescriptor(_obj, "showOnCurrentPage", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showOnCurrentPage"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/components/ad-slot", ["exports", "@ember/object", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "@ember/utils", "discourse/plugins/discourse-adplugin/discourse/helpers/slot-position"], function (_exports, _object, _adComponent, _decorators, _utils, _slotPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.slotContenders = slotContenders;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var adConfig = _object.default.create({
    "google-adsense": {
      settingPrefix: "adsense",
      // settings follow naming convention
      enabledSetting: "adsense_publisher_code",
      nthPost: "adsense_nth_post_code"
    },
    "google-dfp-ad": {
      settingPrefix: "dfp",
      // settings follow naming convention
      enabledSetting: "dfp_publisher_id",
      nthPost: "dfp_nth_post_code"
    },
    "amazon-product-links": {
      settingPrefix: "amazon",
      enabledSetting: false,
      nthPost: "amazon_nth_post_code",
      desktop: {
        "topic-list-top": "amazon_topic_list_top_src_code",
        "post-bottom": "amazon_post_bottom_src_code",
        "topic-above-post-stream": "amazon_topic_above_post_stream_src_code",
        "topic-above-suggested": "amazon_topic_above_suggested_src_code"
      },
      mobile: {
        "topic-list-top": "amazon_mobile_topic_list_top_src_code",
        "post-bottom": "amazon_mobile_post_bottom_src_code",
        "topic-above-post-stream": "amazon_mobile_topic_above_post_stream_src_code",
        "topic-above-suggested": "amazon_mobile_topic_above_suggested_src_code"
      }
    },
    "carbonads-ad": {
      settingPrefix: "carbonads",
      enabledSetting: "carbonads_serve_id",
      desktop: {
        "topic-list-top": "carbonads_topic_list_top_enabled",
        "post-bottom": false,
        "topic-above-post-stream": "carbonads_above_post_stream_enabled",
        "topic-above-suggested": false
      }
    },
    "adbutler-ad": {
      settingPrefix: "adbutler",
      enabledSetting: "adbutler_publisher_id",
      desktop: {
        "topic-list-top": "adbutler_topic_list_top_zone_id",
        "post-bottom": "adbutler_post_bottom_zone_id",
        "topic-above-post-stream": "adbutler_topic_above_post_stream_zone_id",
        "topic-above-suggested": "adbutler_topic_above_suggested_zone_id"
      },
      mobile: {
        "topic-list-top": "adbutler_mobile_topic_list_top_zone_id",
        "post-bottom": "adbutler_mobile_post_bottom_zone_id",
        "topic-above-post-stream": "adbutler_mobile_topic_above_post_stream_zone_id",
        "topic-above-suggested": "adbutler_mobile_topic_above_suggested_zone_id"
      }
    }
  });

  var displayCounts = {
    houseAds: 0,
    allAds: 0
  };

  function _isNetworkAvailable(siteSettings, enabledNetworkSettingName) {
    // False means there's no setting to enable or disable this ad network.
    // Assume it's always enabled.
    if (enabledNetworkSettingName === false) {
      return true;
    } else {
      return enabledNetworkSettingName && !(0, _utils.isBlank)(siteSettings[enabledNetworkSettingName]);
    }
  }

  function _shouldPlaceAdInSlot(siteSettings, currentPostNumber, positionToPlace) {
    return !currentPostNumber || !positionToPlace || (0, _slotPosition.isNthPost)(parseInt(siteSettings[positionToPlace], 10), currentPostNumber);
  }

  function slotContenders(site, siteSettings, placement, indexNumber, postNumber) {
    var types = [];
    var houseAds = site.get("house_creatives"),
        placeUnderscored = placement.replace(/-/g, "_");

    if (houseAds && houseAds.settings) {
      var adsForSlot = houseAds.settings[placeUnderscored];
      var adAvailable = Object.keys(houseAds.creatives).length > 0 && !(0, _utils.isBlank)(adsForSlot); // postNumber and indexNumber are both null for topic-list-top, topic-above-post-stream,
      // and topic-above-suggested placements. Assume we want to place an ad outside the topic list.

      var notPlacingBetweenTopics = !postNumber && !indexNumber;
      var canBePlacedInBetweenTopics = placeUnderscored === "topic_list_between" && (0, _slotPosition.isNthTopicListItem)(parseInt(houseAds.settings.after_nth_topic, 10), indexNumber);

      if (adAvailable && (notPlacingBetweenTopics || canBePlacedInBetweenTopics || (0, _slotPosition.isNthPost)(parseInt(houseAds.settings.after_nth_post, 10), postNumber))) {
        types.push("house-ad");
      }
    }

    Object.keys(adConfig).forEach(function (adNetwork) {
      var config = adConfig[adNetwork];
      var settingNames = null,
          name;

      if (_isNetworkAvailable(siteSettings, config.enabledSetting) && _shouldPlaceAdInSlot(siteSettings, postNumber, config.nthPost)) {
        if (site.mobileView) {
          settingNames = config.mobile || config.desktop;
        } else {
          settingNames = config.desktop;
        }

        if (settingNames) {
          name = settingNames[placement];
        }

        if (name === undefined) {
          // follows naming convention: prefix_(mobile_)_{placement}_code
          name = "".concat(config.settingPrefix, "_").concat(site.mobileView ? "mobile_" : "").concat(placeUnderscored, "_code");
        }

        if (name !== false && siteSettings[name] !== false && !(0, _utils.isBlank)(siteSettings[name])) {
          types.push(adNetwork);
        }
      }
    });
    return types;
  }

  var _default = _adComponent.default.extend((_dec = (0, _decorators.default)("placement", "postNumber", "indexNumber"), _dec2 = (0, _decorators.observes)("refreshOnChange"), _dec3 = (0, _decorators.default)("placement", "availableAdTypes", "needsUpdate"), (_obj = {
    needsUpdate: false,
    tagName: "",
    availableAdTypes: function availableAdTypes(placement, postNumber, indexNumber) {
      return slotContenders(this.site, this.siteSettings, placement, indexNumber, postNumber);
    },
    changed: function changed() {
      if (this.get("listLoading")) {
        return;
      } // force adComponents to be recomputed


      this.notifyPropertyChange("needsUpdate");
    },
    adComponents: function adComponents(placement, availableAdTypes) {
      if (!availableAdTypes.includes("house-ad") || availableAdTypes.length === 1) {
        // Current behaviour is to allow multiple ads from different networks
        // to show in the same place. We could change this to choose one somehow.
        return availableAdTypes;
      }

      var houseAds = this.site.get("house_creatives");
      var houseAdsSkipped = false;

      if (houseAds.settings.house_ads_frequency === 100) {
        // house always wins
        return ["house-ad"];
      } else if (houseAds.settings.house_ads_frequency > 0) {
        // show house ads the given percent of the time
        if (displayCounts.allAds === 0 || 100 * displayCounts.houseAds / displayCounts.allAds < houseAds.settings.house_ads_frequency) {
          displayCounts.houseAds += 1;
          displayCounts.allAds += 1;
          return ["house-ad"];
        } else {
          houseAdsSkipped = true;
        }
      }

      var networkNames = availableAdTypes.filter(function (x) {
        return x !== "house-ad";
      });

      if (houseAdsSkipped) {
        displayCounts.allAds += networkNames.length;
      }

      return networkNames;
    }
  }, (_applyDecoratedDescriptor(_obj, "availableAdTypes", [_dec], Object.getOwnPropertyDescriptor(_obj, "availableAdTypes"), _obj), _applyDecoratedDescriptor(_obj, "changed", [_dec2], Object.getOwnPropertyDescriptor(_obj, "changed"), _obj), _applyDecoratedDescriptor(_obj, "adComponents", [_dec3], Object.getOwnPropertyDescriptor(_obj, "adComponents"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/components/adbutler-ad", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "discourse/lib/load-script", "rsvp", "@ember/runloop", "discourse-common/config/environment"], function (_exports, _adComponent, _decorators, _loadScript, _rsvp, _runloop, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _loaded = false,
      _promise = null,
      _c = 0;

  function loadAdbutler(adserverHostname) {
    if (_loaded) {
      return _rsvp.default.resolve();
    }

    if (_promise) {
      return _promise;
    }

    _promise = (0, _loadScript.default)("https://" + adserverHostname + "/app.js", {
      scriptTag: true
    }).then(function () {
      _loaded = true;
    });
    return _promise;
  }

  var _default = _adComponent.default.extend((_dec = (0, _decorators.observes)("listLoading"), _dec2 = (0, _decorators.default)("currentUser.trust_level"), _dec3 = (0, _decorators.default)("publisherId", "showToTrustLevel", "showToGroups", "showAfterPost", "showOnCurrentPage"), _dec4 = (0, _decorators.default)("postNumber"), (_obj = {
    divs: null,
    publisherId: null,
    init: function init() {
      var dimensions = [728, 90];
      var configKey = "adbutler_";
      var className = "adbutler-";
      var dimClassName = "adbutler-ad";
      this.set("divs", []);

      if (this.site.mobileView) {
        dimensions = [320, 50];
        configKey += "mobile_";
        className += "mobile-";
        dimClassName = "adbutler-mobile-ad";
      }

      configKey += this.get("placement").replace(/-/g, "_") + "_zone_id";
      this.set("configKey", configKey);
      className += this.get("placement");
      this.set("className", className + " " + dimClassName);
      var zoneId = this.siteSettings[configKey];
      this.set("zoneId", zoneId);
      var divId = "placement-" + zoneId + "-" + _c;
      this.set("divId", divId);
      var publisherId = this.siteSettings.adbutler_publisher_id;
      this.set("publisherId", publisherId);
      _c++;
      this.divs.push({
        divId: divId,
        publisherId: publisherId,
        zoneId: zoneId,
        dimensions: dimensions
      });

      this._super();
    },
    _triggerAds: function _triggerAds() {
      if ((0, _environment.isTesting)()) {
        return; // Don't load external JS during tests
      }

      var adserverHostname = this.siteSettings.adbutler_adserver_hostname;
      loadAdbutler(adserverHostname).then(function () {
        if (this.divs.length > 0) {
          var abkw = window.abkw || "";
          window.AdButler.ads.push({
            handler: function handler(opt) {
              window.AdButler.register(opt.place.publisherId, opt.place.zoneId, opt.place.dimensions, opt.place.divId, opt);
            },
            opt: {
              place: this.divs.pop(),
              keywords: abkw,
              domain: adserverHostname,
              click: "CLICK_MACRO_PLACEHOLDER"
            }
          });
        }
      }.bind(this));
    },
    didInsertElement: function didInsertElement() {
      this._super();

      (0, _runloop.scheduleOnce)("afterRender", this, this._triggerAds);
    },
    waitForLoad: function waitForLoad() {
      if (this.get("adRequested")) {
        return;
      } // already requested that this ad unit be populated


      if (!this.get("listLoading")) {
        (0, _runloop.scheduleOnce)("afterRender", this, this._triggerAds);
      }
    },
    showToTrustLevel: function showToTrustLevel(trustLevel) {
      return !(trustLevel && trustLevel > this.siteSettings.adbutler_through_trust_level);
    },
    showAd: function showAd(publisherId, showToTrustLevel, showToGroups, showAfterPost, showOnCurrentPage) {
      return publisherId && showToTrustLevel && showToGroups && showAfterPost && showOnCurrentPage;
    },
    showAfterPost: function showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }

      return this.isNthPost(parseInt(this.siteSettings.adbutler_nth_post, 10));
    }
  }, (_applyDecoratedDescriptor(_obj, "waitForLoad", [_dec], Object.getOwnPropertyDescriptor(_obj, "waitForLoad"), _obj), _applyDecoratedDescriptor(_obj, "showToTrustLevel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showToTrustLevel"), _obj), _applyDecoratedDescriptor(_obj, "showAd", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showAd"), _obj), _applyDecoratedDescriptor(_obj, "showAfterPost", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showAfterPost"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/components/amazon-product-links", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/template"], function (_exports, _adComponent, _decorators, _computed, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _adComponent.default.extend((_dec = (0, _decorators.default)("amazon_width", "amazon_height"), _dec2 = (0, _decorators.default)("mobile_amazon_width", "mobile_amazon_height"), _dec3 = (0, _decorators.default)("mobile_amazon_width"), _dec4 = (0, _decorators.default)("user_input"), _dec5 = (0, _decorators.default)("user_input_mobile"), _dec6 = (0, _decorators.default)("currentUser.trust_level"), _dec7 = (0, _decorators.default)("postNumber"), (_obj = {
    classNames: ["amazon-product-links"],
    showAd: (0, _computed.and)("showToTrustLevel", "showToGroups", "showAfterPost", "showOnCurrentPage"),
    init: function init() {
      var data = {
        "topic-list-top": {},
        "topic-above-post-stream": {},
        "topic-above-suggested": {},
        "post-bottom": {}
      };
      var mobileView = this.site.get("mobileView");
      var placement = this.get("placement");

      if (!mobileView && this.siteSettings.amazon_topic_list_top_src_code) {
        data["topic-list-top"]["user_input"] = this.siteSettings.amazon_topic_list_top_src_code;
        data["topic-list-top"]["amazon_width"] = parseInt(this.siteSettings.amazon_topic_list_top_ad_width_code, 10);
        data["topic-list-top"]["amazon_height"] = parseInt(this.siteSettings.amazon_topic_list_top_ad_height_code, 10);
      }

      if (mobileView && this.siteSettings.amazon_mobile_topic_list_top_src_code) {
        data["topic-list-top"]["user_input_mobile"] = this.siteSettings.amazon_mobile_topic_list_top_src_code;
        data["topic-list-top"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_topic_list_top_ad_width_code, 10);
        data["topic-list-top"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_topic_list_top_ad_height_code, 10);
      }

      if (!mobileView && this.siteSettings.amazon_topic_above_post_stream_src_code) {
        data["topic-above-post-stream"]["user_input"] = this.siteSettings.amazon_topic_above_post_stream_src_code;
        data["topic-above-post-stream"]["amazon_width"] = parseInt(this.siteSettings.amazon_topic_above_post_stream_ad_width_code, 10);
        data["topic-above-post-stream"]["amazon_height"] = parseInt(this.siteSettings.amazon_topic_above_post_stream_ad_height_code, 10);
      }

      if (mobileView && this.siteSettings.amazon_mobile_topic_above_post_stream_src_code) {
        data["topic-above-post-stream"]["user_input_mobile"] = this.siteSettings.amazon_mobile_topic_above_post_stream_src_code;
        data["topic-above-post-stream"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_topic_above_post_stream_ad_width_code, 10);
        data["topic-above-post-stream"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_topic_above_post_stream_ad_height_code, 10);
      }

      if (!mobileView && this.siteSettings.amazon_topic_above_suggested_src_code) {
        data["topic-above-suggested"]["user_input"] = this.siteSettings.amazon_topic_above_suggested_src_code;
        data["topic-above-suggested"]["amazon_width"] = parseInt(this.siteSettings.amazon_topic_above_suggested_ad_width_code, 10);
        data["topic-above-suggested"]["amazon_height"] = parseInt(this.siteSettings.amazon_topic_above_suggested_ad_height_code, 10);
      }

      if (mobileView && this.siteSettings.amazon_mobile_topic_above_suggested_src_code) {
        data["topic-above-suggested"]["user_input_mobile"] = this.siteSettings.amazon_mobile_topic_above_suggested_src_code;
        data["topic-above-suggested"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_topic_above_suggested_ad_width_code, 10);
        data["topic-above-suggested"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_topic_above_suggested_ad_height_code, 10);
      }

      if (!mobileView && this.siteSettings.amazon_post_bottom_src_code) {
        data["post-bottom"]["user_input"] = this.siteSettings.amazon_post_bottom_src_code;
        data["post-bottom"]["amazon_width"] = parseInt(this.siteSettings.amazon_post_bottom_ad_width_code, 10);
        data["post-bottom"]["amazon_height"] = parseInt(this.siteSettings.amazon_post_bottom_ad_height_code, 10);
      }

      if (mobileView && this.siteSettings.amazon_mobile_post_bottom_src_code) {
        data["post-bottom"]["user_input_mobile"] = this.siteSettings.amazon_mobile_post_bottom_src_code;
        data["post-bottom"]["mobile_amazon_width"] = parseInt(this.siteSettings.amazon_mobile_post_bottom_ad_width_code, 10);
        data["post-bottom"]["mobile_amazon_height"] = parseInt(this.siteSettings.amazon_mobile_post_bottom_ad_height_code, 10);
      }

      this.set("user_input", data[placement]["user_input"]);
      this.set("amazon_width", data[placement]["amazon_width"]);
      this.set("amazon_height", data[placement]["amazon_height"]);
      this.set("user_input_mobile", data[placement]["user_input_mobile"]);
      this.set("mobile_amazon_height", data[placement]["mobile_amazon_height"]);
      this.set("mobile_amazon_width", data[placement]["mobile_amazon_width"]);

      this._super();
    },
    adWrapperStyle: function adWrapperStyle(w, h) {
      return (0, _template.htmlSafe)("width: ".concat(w, "px; height: ").concat(h, "px;"));
    },
    adWrapperStyleMobile: function adWrapperStyleMobile(w, h) {
      return (0, _template.htmlSafe)("width: ".concat(w, "px; height: ").concat(h, "px;"));
    },
    adTitleStyleMobile: function adTitleStyleMobile(w) {
      return (0, _template.htmlSafe)("width: ".concat(w, "px;"));
    },
    userInput: function userInput(_userInput) {
      return (0, _template.htmlSafe)("".concat(_userInput));
    },
    userInputMobile: function userInputMobile(userInput) {
      return (0, _template.htmlSafe)("".concat(userInput));
    },
    showToTrustLevel: function showToTrustLevel(trustLevel) {
      return !(trustLevel && trustLevel > this.siteSettings.amazon_through_trust_level);
    },
    showAfterPost: function showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }

      return this.isNthPost(parseInt(this.siteSettings.amazon_nth_post_code, 10));
    }
  }, (_applyDecoratedDescriptor(_obj, "adWrapperStyle", [_dec], Object.getOwnPropertyDescriptor(_obj, "adWrapperStyle"), _obj), _applyDecoratedDescriptor(_obj, "adWrapperStyleMobile", [_dec2], Object.getOwnPropertyDescriptor(_obj, "adWrapperStyleMobile"), _obj), _applyDecoratedDescriptor(_obj, "adTitleStyleMobile", [_dec3], Object.getOwnPropertyDescriptor(_obj, "adTitleStyleMobile"), _obj), _applyDecoratedDescriptor(_obj, "userInput", [_dec4], Object.getOwnPropertyDescriptor(_obj, "userInput"), _obj), _applyDecoratedDescriptor(_obj, "userInputMobile", [_dec5], Object.getOwnPropertyDescriptor(_obj, "userInputMobile"), _obj), _applyDecoratedDescriptor(_obj, "showToTrustLevel", [_dec6], Object.getOwnPropertyDescriptor(_obj, "showToTrustLevel"), _obj), _applyDecoratedDescriptor(_obj, "showAfterPost", [_dec7], Object.getOwnPropertyDescriptor(_obj, "showAfterPost"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/components/carbonads-ad", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "@ember/template"], function (_exports, _adComponent, _decorators, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _adComponent.default.extend((_dec = (0, _decorators.default)("serve_id", "placement"), _dec2 = (0, _decorators.default)("currentUser.trust_level"), _dec3 = (0, _decorators.default)("placement", "serve_id", "showToTrustLevel", "showToGroups", "showOnCurrentPage"), (_obj = {
    serve_id: null,
    placement: null,
    init: function init() {
      this.set("serve_id", this.siteSettings.carbonads_serve_id);
      this.set("placement", this.siteSettings.carbonads_placement);

      this._super();
    },
    url: function url(serveId, placement) {
      return (0, _template.htmlSafe)("//cdn.carbonads.com/carbon.js?serve=".concat(serveId, "&placement=").concat(placement));
    },
    showToTrustLevel: function showToTrustLevel(trustLevel) {
      return !(trustLevel && trustLevel > this.siteSettings.carbonads_through_trust_level);
    },
    showAd: function showAd(placement, serveId, showToTrustLevel, showToGroups, showOnCurrentPage) {
      return placement && serveId && showToTrustLevel && showToGroups && showOnCurrentPage;
    }
  }, (_applyDecoratedDescriptor(_obj, "url", [_dec], Object.getOwnPropertyDescriptor(_obj, "url"), _obj), _applyDecoratedDescriptor(_obj, "showToTrustLevel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showToTrustLevel"), _obj), _applyDecoratedDescriptor(_obj, "showAd", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showAd"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/components/google-adsense", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "discourse/lib/load-script", "rsvp", "@ember/runloop", "discourse-common/config/environment", "@ember/template"], function (_exports, _adComponent, _decorators, _loadScript, _rsvp, _runloop, _environment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _loaded = false,
      _promise = null,
      renderCounts = {};

  function parseAdWidth(value) {
    if (value === "responsive") {
      return "auto";
    }

    if (value.startsWith("fluid")) {
      return "fluid";
    }

    var w = parseInt(value.substring(0, 3).trim(), 10);

    if (isNaN(w)) {
      return "auto";
    } else {
      return "".concat(w, "px");
    }
  }

  function parseAdHeight(value) {
    if (value === "responsive") {
      return "auto";
    }

    if (value.startsWith("fluid")) {
      return "fluid";
    }

    var h = parseInt(value.substring(4, 7).trim(), 10);

    if (isNaN(h)) {
      return "auto";
    } else {
      return "".concat(h, "px");
    }
  }

  function loadAdsense() {
    if (_loaded) {
      return _rsvp.default.resolve();
    }

    if (_promise) {
      return _promise;
    }

    var adsenseSrc = ("https:" === document.location.protocol ? "https:" : "http:") + "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    _promise = (0, _loadScript.default)(adsenseSrc, {
      scriptTag: true
    }).then(function () {
      _loaded = true;
    });
    return _promise;
  }

  var DESKTOP_SETTINGS = {
    "topic-list-top": {
      code: "adsense_topic_list_top_code",
      sizes: "adsense_topic_list_top_ad_sizes"
    },
    "topic-above-post-stream": {
      code: "adsense_topic_above_post_stream_code",
      sizes: "adsense_topic_above_post_stream_ad_sizes"
    },
    "topic-above-suggested": {
      code: "adsense_topic_above_suggested_code",
      sizes: "adsense_topic_above_suggested_ad_sizes"
    },
    "post-bottom": {
      code: "adsense_post_bottom_code",
      sizes: "adsense_post_bottom_ad_sizes"
    }
  };
  var MOBILE_SETTINGS = {
    "topic-list-top": {
      code: "adsense_mobile_topic_list_top_code",
      sizes: "adsense_mobile_topic_list_top_ad_size"
    },
    "topic-above-post-stream": {
      code: "adsense_mobile_topic_above_post_stream_code",
      sizes: "adsense_mobile_topic_above_post_stream_ad_size"
    },
    "topic-above-suggested": {
      code: "adsense_mobile_topic_above_suggested_code",
      sizes: "adsense_mobile_topic_above_suggested_ad_size"
    },
    "post-bottom": {
      code: "adsense_mobile_post_bottom_code",
      sizes: "adsense_mobile_post_bottom_ad_size"
    }
  };

  var _default = _adComponent.default.extend((_dec = (0, _decorators.observes)("listLoading"), _dec2 = (0, _decorators.default)("ad_width"), _dec3 = (0, _decorators.default)("ad_width"), _dec4 = (0, _decorators.default)("placement", "showAd"), _dec5 = (0, _decorators.default)("isResponsive", "isFluid"), _dec6 = (0, _decorators.default)("ad_width", "ad_height", "isResponsive"), _dec7 = (0, _decorators.default)("adWrapperStyle", "isResponsive"), _dec8 = (0, _decorators.default)("currentUser.trust_level"), _dec9 = (0, _decorators.default)("publisher_id", "showToTrustLevel", "showToGroups", "showAfterPost", "showOnCurrentPage"), _dec10 = (0, _decorators.default)("postNumber"), (_obj = {
    classNameBindings: [":google-adsense", "classForSlot", "isResponsive:adsense-responsive"],
    loadedGoogletag: false,
    publisher_id: null,
    ad_width: null,
    ad_height: null,
    adRequested: false,
    init: function init() {
      var config, size;
      var placement = this.get("placement");

      if (this.site.mobileView) {
        config = MOBILE_SETTINGS[placement];
      } else {
        config = DESKTOP_SETTINGS[placement];
      }

      if (!renderCounts[placement]) {
        renderCounts[placement] = 0;
      }

      var sizes = (this.siteSettings[config.sizes] || "").split("|");

      if (sizes.length === 1) {
        size = sizes[0];
      } else {
        size = sizes[renderCounts[placement] % sizes.length];
        renderCounts[placement] += 1;
      }

      this.set("ad_width", parseAdWidth(size));
      this.set("ad_height", parseAdHeight(size));
      this.set("ad_code", this.siteSettings[config.code]);
      this.set("publisher_id", this.siteSettings.adsense_publisher_code);

      this._super();
    },
    _triggerAds: function _triggerAds() {
      if ((0, _environment.isTesting)()) {
        return; // Don't load external JS during tests
      }

      this.set("adRequested", true);
      loadAdsense().then(function () {
        var adsbygoogle = window.adsbygoogle || [];

        try {
          adsbygoogle.push({}); // ask AdSense to fill one ad unit
        } catch (ex) {}
      });
    },
    didInsertElement: function didInsertElement() {
      this._super();

      if (!this.get("showAd")) {
        return;
      }

      if (this.get("listLoading")) {
        return;
      }

      (0, _runloop.scheduleOnce)("afterRender", this, this._triggerAds);
    },
    waitForLoad: function waitForLoad() {
      if (this.get("adRequested")) {
        return;
      } // already requested that this ad unit be populated


      if (!this.get("listLoading")) {
        (0, _runloop.scheduleOnce)("afterRender", this, this._triggerAds);
      }
    },
    isResponsive: function isResponsive(adWidth) {
      return ["auto", "fluid"].includes(adWidth);
    },
    isFluid: function isFluid(adWidth) {
      return adWidth === "fluid";
    },
    classForSlot: function classForSlot(placement, showAd) {
      return showAd ? (0, _template.htmlSafe)("adsense-".concat(placement)) : "";
    },
    autoAdFormat: function autoAdFormat(isResponsive, isFluid) {
      return isResponsive ? (0, _template.htmlSafe)(isFluid ? "fluid" : "auto") : false;
    },
    adWrapperStyle: function adWrapperStyle(w, h, isResponsive) {
      return (0, _template.htmlSafe)(isResponsive ? "" : "width: ".concat(w, "; height: ").concat(h, ";"));
    },
    adInsStyle: function adInsStyle(adWrapperStyle, isResponsive) {
      return (0, _template.htmlSafe)("display: ".concat(isResponsive ? "block" : "inline-block", "; ").concat(adWrapperStyle));
    },
    showToTrustLevel: function showToTrustLevel(trustLevel) {
      return !(trustLevel && trustLevel > this.siteSettings.adsense_through_trust_level);
    },
    showAd: function showAd(publisherId, showToTrustLevel, showToGroups, showAfterPost, showOnCurrentPage) {
      return publisherId && showToTrustLevel && showToGroups && showAfterPost && showOnCurrentPage;
    },
    showAfterPost: function showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }

      return this.isNthPost(parseInt(this.siteSettings.adsense_nth_post_code, 10));
    }
  }, (_applyDecoratedDescriptor(_obj, "waitForLoad", [_dec], Object.getOwnPropertyDescriptor(_obj, "waitForLoad"), _obj), _applyDecoratedDescriptor(_obj, "isResponsive", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isResponsive"), _obj), _applyDecoratedDescriptor(_obj, "isFluid", [_dec3], Object.getOwnPropertyDescriptor(_obj, "isFluid"), _obj), _applyDecoratedDescriptor(_obj, "classForSlot", [_dec4], Object.getOwnPropertyDescriptor(_obj, "classForSlot"), _obj), _applyDecoratedDescriptor(_obj, "autoAdFormat", [_dec5], Object.getOwnPropertyDescriptor(_obj, "autoAdFormat"), _obj), _applyDecoratedDescriptor(_obj, "adWrapperStyle", [_dec6], Object.getOwnPropertyDescriptor(_obj, "adWrapperStyle"), _obj), _applyDecoratedDescriptor(_obj, "adInsStyle", [_dec7], Object.getOwnPropertyDescriptor(_obj, "adInsStyle"), _obj), _applyDecoratedDescriptor(_obj, "showToTrustLevel", [_dec8], Object.getOwnPropertyDescriptor(_obj, "showToTrustLevel"), _obj), _applyDecoratedDescriptor(_obj, "showAd", [_dec9], Object.getOwnPropertyDescriptor(_obj, "showAd"), _obj), _applyDecoratedDescriptor(_obj, "showAfterPost", [_dec10], Object.getOwnPropertyDescriptor(_obj, "showAfterPost"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/components/google-dfp-ad", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "discourse/lib/load-script", "@ember/object/computed", "rsvp", "discourse-common/config/environment", "@ember/template"], function (_exports, _adComponent, _decorators, _loadScript, _computed, _rsvp, _environment, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _loaded = false,
      _promise = null,
      ads = {},
      nextSlotNum = 1,
      renderCounts = {};

  function getNextSlotNum() {
    return nextSlotNum++;
  }

  function splitWidthInt(value) {
    var str = value.substring(0, 3);
    return str.trim();
  }

  function splitHeightInt(value) {
    var str = value.substring(4, 7);
    return str.trim();
  } // This creates an array for the values of the custom targeting key


  function valueParse(value) {
    var final = value.replace(/ /g, "");
    final = final.replace(/['"]+/g, "");
    final = final.split(",");
    return final;
  } // This creates an array for the key of the custom targeting key


  function keyParse(word) {
    var key = word;
    key = key.replace(/['"]+/g, "");
    key = key.split("\n");
    return key;
  } // This should call adslot.setTargeting(key for that location, value for that location)


  function custom_targeting(key_array, value_array, adSlot) {
    for (var i = 0; i < key_array.length; i++) {
      if (key_array[i]) {
        adSlot.setTargeting(key_array[i], valueParse(value_array[i]));
      }
    }
  }

  var DESKTOP_SETTINGS = {
    "topic-list-top": {
      code: "dfp_topic_list_top_code",
      sizes: "dfp_topic_list_top_ad_sizes",
      targeting_keys: "dfp_target_topic_list_top_key_code",
      targeting_values: "dfp_target_topic_list_top_value_code"
    },
    "topic-above-post-stream": {
      code: "dfp_topic_above_post_stream_code",
      sizes: "dfp_topic_above_post_stream_ad_sizes",
      targeting_keys: "dfp_target_topic_above_post_stream_key_code",
      targeting_values: "dfp_target_topic_above_post_stream_value_code"
    },
    "topic-above-suggested": {
      code: "dfp_topic_above_suggested_code",
      sizes: "dfp_topic_above_suggested_ad_sizes",
      targeting_keys: "dfp_target_topic_above_suggested_key_code",
      targeting_values: "dfp_target_topic_above_suggested_value_code"
    },
    "post-bottom": {
      code: "dfp_post_bottom_code",
      sizes: "dfp_post_bottom_ad_sizes",
      targeting_keys: "dfp_target_post_bottom_key_code",
      targeting_values: "dfp_target_post_bottom_value_code"
    }
  };
  var MOBILE_SETTINGS = {
    "topic-list-top": {
      code: "dfp_mobile_topic_list_top_code",
      sizes: "dfp_mobile_topic_list_top_ad_sizes",
      targeting_keys: "dfp_target_topic_list_top_key_code",
      targeting_values: "dfp_target_topic_list_top_value_code"
    },
    "topic-above-post-stream": {
      code: "dfp_mobile_topic_above_post_stream_code",
      sizes: "dfp_mobile_topic_above_post_stream_ad_sizes",
      targeting_keys: "dfp_target_topic_above_post_stream_key_code",
      targeting_values: "dfp_target_topic_above_post_stream_value_code"
    },
    "topic-above-suggested": {
      code: "dfp_mobile_topic_above_suggested_code",
      sizes: "dfp_mobile_topic_above_suggested_ad_sizes",
      targeting_keys: "dfp_target_topic_above_suggested_key_code",
      targeting_values: "dfp_target_topic_above_suggested_value_code"
    },
    "post-bottom": {
      code: "dfp_mobile_post_bottom_code",
      sizes: "dfp_mobile_post_bottom_ad_sizes",
      targeting_keys: "dfp_target_post_bottom_key_code",
      targeting_values: "dfp_target_post_bottom_value_code"
    }
  };

  function getWidthAndHeight(placement, settings, isMobile) {
    var config, size;

    if (isMobile) {
      config = MOBILE_SETTINGS[placement];
    } else {
      config = DESKTOP_SETTINGS[placement];
    }

    if (!renderCounts[placement]) {
      renderCounts[placement] = 0;
    }

    var sizes = (settings[config.sizes] || "").split("|");

    if (sizes.length === 1) {
      size = sizes[0];
    } else {
      size = sizes[renderCounts[placement] % sizes.length];
      renderCounts[placement] += 1;
    }

    if (size === "fluid") {
      return {
        width: "fluid",
        height: "fluid"
      };
    }

    var sizeObj = {
      width: parseInt(splitWidthInt(size), 10),
      height: parseInt(splitHeightInt(size), 10)
    };

    if (!isNaN(sizeObj.width) && !isNaN(sizeObj.height)) {
      return sizeObj;
    }
  }

  function defineSlot(divId, placement, settings, isMobile, width, height, categoryTarget) {
    if (!settings.dfp_publisher_id) {
      return;
    }

    if (ads[divId]) {
      return ads[divId];
    }

    var ad, config, publisherId;

    if (isMobile) {
      publisherId = settings.dfp_publisher_id_mobile || settings.dfp_publisher_id;
      config = MOBILE_SETTINGS[placement];
    } else {
      publisherId = settings.dfp_publisher_id;
      config = DESKTOP_SETTINGS[placement];
    }

    ad = window.googletag.defineSlot("/" + publisherId + "/" + settings[config.code], [width, height], divId);
    custom_targeting(keyParse(settings[config.targeting_keys]), keyParse(settings[config.targeting_values]), ad);

    if (categoryTarget) {
      ad.setTargeting("discourse-category", categoryTarget);
    }

    ad.addService(window.googletag.pubads());
    ads[divId] = {
      ad: ad,
      width: width,
      height: height
    };
    return ads[divId];
  }

  function destroySlot(divId) {
    if (ads[divId] && window.googletag) {
      window.googletag.destroySlots([ads[divId].ad]);
      delete ads[divId];
    }
  }

  function loadGoogle() {
    /**
     * Refer to this article for help:
     * https://support.google.com/admanager/answer/4578089?hl=en
     */
    if (_loaded) {
      return _rsvp.default.resolve();
    }

    if (_promise) {
      return _promise;
    } // The boilerplate code


    var dfpSrc = ("https:" === document.location.protocol ? "https:" : "http:") + "//securepubads.g.doubleclick.net/tag/js/gpt.js";
    _promise = (0, _loadScript.default)(dfpSrc, {
      scriptTag: true
    }).then(function () {
      _loaded = true;

      if (window.googletag === undefined) {
        // eslint-disable-next-line no-console
        console.log("googletag is undefined!");
      }

      window.googletag.cmd.push(function () {
        // Infinite scroll requires SRA:
        window.googletag.pubads().enableSingleRequest(); // we always use refresh() to fetch the ads:

        window.googletag.pubads().disableInitialLoad();
        window.googletag.enableServices();
      });
    });
    window.googletag = window.googletag || {
      cmd: []
    };
    return _promise;
  }

  var _default = _adComponent.default.extend((_dec = (0, _decorators.default)("siteSettings.dfp_publisher_id", "siteSettings.dfp_publisher_id_mobile", "site.mobileView"), _dec2 = (0, _decorators.default)("placement", "postNumber"), _dec3 = (0, _decorators.default)("placement", "showAd"), _dec4 = (0, _decorators.default)("width", "height"), _dec5 = (0, _decorators.default)("width"), _dec6 = (0, _decorators.default)("publisherId", "showToTrustLevel", "showToGroups", "showAfterPost", "showOnCurrentPage", "size"), _dec7 = (0, _decorators.default)("currentUser.trust_level"), _dec8 = (0, _decorators.default)("postNumber"), _dec9 = (0, _decorators.on)("didUpdate"), _dec10 = (0, _decorators.on)("didInsertElement"), _dec11 = (0, _decorators.on)("willDestroyElement"), (_obj = {
    classNameBindings: ["adUnitClass"],
    classNames: ["google-dfp-ad"],
    loadedGoogletag: false,
    refreshOnChange: null,
    lastAdRefresh: null,
    width: (0, _computed.alias)("size.width"),
    height: (0, _computed.alias)("size.height"),
    size: function size() {
      return getWidthAndHeight(this.get("placement"), this.siteSettings, this.site.mobileView);
    },
    publisherId: function publisherId(globalId, mobileId, isMobile) {
      if (isMobile) {
        return mobileId || globalId;
      } else {
        return globalId;
      }
    },
    divId: function divId(placement, postNumber) {
      var slotNum = getNextSlotNum();

      if (postNumber) {
        return "div-gpt-ad-".concat(slotNum, "-").concat(placement, "-").concat(postNumber);
      } else {
        return "div-gpt-ad-".concat(slotNum, "-").concat(placement);
      }
    },
    adUnitClass: function adUnitClass(placement, showAd) {
      return showAd ? "dfp-ad-".concat(placement) : "";
    },
    adWrapperStyle: function adWrapperStyle(w, h) {
      if (w !== "fluid") {
        return (0, _template.htmlSafe)("width: ".concat(w, "px; height: ").concat(h, "px;"));
      }
    },
    adTitleStyleMobile: function adTitleStyleMobile(w) {
      if (w !== "fluid") {
        return (0, _template.htmlSafe)("width: ".concat(w, "px;"));
      }
    },
    showAd: function showAd(publisherId, showToTrustLevel, showToGroups, showAfterPost, showOnCurrentPage, size) {
      return publisherId && showToTrustLevel && showToGroups && showAfterPost && showOnCurrentPage && size;
    },
    showToTrustLevel: function showToTrustLevel(trustLevel) {
      return !(trustLevel && trustLevel > this.siteSettings.dfp_through_trust_level);
    },
    showAfterPost: function showAfterPost(postNumber) {
      if (!postNumber) {
        return true;
      }

      return this.isNthPost(parseInt(this.siteSettings.dfp_nth_post_code, 10));
    },
    // 3 second delay between calls to refresh ads in a component.
    // Ember often calls updated() more than once, and *sometimes*
    // updated() is called after _initGoogleDFP().
    shouldRefreshAd: function shouldRefreshAd() {
      var lastAdRefresh = this.get("lastAdRefresh");

      if (!lastAdRefresh) {
        return true;
      }

      return new Date() - lastAdRefresh > 3000;
    },
    updated: function updated() {
      if (this.get("listLoading") || !this.shouldRefreshAd()) {
        return;
      }

      var slot = ads[this.get("divId")];

      if (!(slot && slot.ad)) {
        return;
      }

      var ad = slot.ad,
          categorySlug = this.get("currentCategorySlug");

      if (this.get("loadedGoogletag")) {
        this.set("lastAdRefresh", new Date());
        window.googletag.cmd.push(function () {
          ad.setTargeting("discourse-category", categorySlug || "0");
          window.googletag.pubads().refresh([ad]);
        });
      }
    },
    _initGoogleDFP: function _initGoogleDFP() {
      var _this = this;

      if ((0, _environment.isTesting)()) {
        return; // Don't load external JS during tests
      }

      if (!this.get("showAd")) {
        return;
      }

      loadGoogle().then(function () {
        _this.set("loadedGoogletag", true);

        _this.set("lastAdRefresh", new Date());

        window.googletag.cmd.push(function () {
          var slot = defineSlot(_this.get("divId"), _this.get("placement"), _this.siteSettings, _this.site.mobileView, _this.get("width"), _this.get("height"), _this.get("currentCategorySlug") || "0");

          if (slot && slot.ad) {
            // Display has to be called before refresh
            // and after the slot div is in the page.
            window.googletag.display(_this.get("divId"));
            window.googletag.pubads().refresh([slot.ad]);
          }
        });
      });
    },
    willRender: function willRender() {
      this._super.apply(this, arguments);

      if (!this.get("showAd")) {
        return;
      }
    },
    cleanup: function cleanup() {
      destroySlot(this.get("divId"));
    }
  }, (_applyDecoratedDescriptor(_obj, "size", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "size"), _obj), _applyDecoratedDescriptor(_obj, "publisherId", [_dec], Object.getOwnPropertyDescriptor(_obj, "publisherId"), _obj), _applyDecoratedDescriptor(_obj, "divId", [_dec2], Object.getOwnPropertyDescriptor(_obj, "divId"), _obj), _applyDecoratedDescriptor(_obj, "adUnitClass", [_dec3], Object.getOwnPropertyDescriptor(_obj, "adUnitClass"), _obj), _applyDecoratedDescriptor(_obj, "adWrapperStyle", [_dec4], Object.getOwnPropertyDescriptor(_obj, "adWrapperStyle"), _obj), _applyDecoratedDescriptor(_obj, "adTitleStyleMobile", [_dec5], Object.getOwnPropertyDescriptor(_obj, "adTitleStyleMobile"), _obj), _applyDecoratedDescriptor(_obj, "showAd", [_dec6], Object.getOwnPropertyDescriptor(_obj, "showAd"), _obj), _applyDecoratedDescriptor(_obj, "showToTrustLevel", [_dec7], Object.getOwnPropertyDescriptor(_obj, "showToTrustLevel"), _obj), _applyDecoratedDescriptor(_obj, "showAfterPost", [_dec8], Object.getOwnPropertyDescriptor(_obj, "showAfterPost"), _obj), _applyDecoratedDescriptor(_obj, "updated", [_dec9], Object.getOwnPropertyDescriptor(_obj, "updated"), _obj), _applyDecoratedDescriptor(_obj, "_initGoogleDFP", [_dec10], Object.getOwnPropertyDescriptor(_obj, "_initGoogleDFP"), _obj), _applyDecoratedDescriptor(_obj, "cleanup", [_dec11], Object.getOwnPropertyDescriptor(_obj, "cleanup"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/components/house-ad", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-component", "discourse-common/utils/decorators", "@ember/utils"], function (_exports, _adComponent, _decorators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var adIndex = {
    topic_list_top: null,
    topic_above_post_stream: null,
    topic_above_suggested: null,
    post_bottom: null,
    topic_list_between: null
  };

  var _default = _adComponent.default.extend((_dec = (0, _decorators.default)("placement", "showAd"), _dec2 = (0, _decorators.default)("showToGroups", "showAfterPost", "showAfterTopicListItem", "showOnCurrentPage"), _dec3 = (0, _decorators.default)("postNumber", "placement"), _dec4 = (0, _decorators.default)("placement"), _dec5 = (0, _decorators.observes)("refreshOnChange"), (_obj = {
    classNames: ["house-creative"],
    classNameBindings: ["adUnitClass"],
    attributeBindings: ["colspanAttribute:colspan"],
    adHtml: "",
    colspanAttribute: function colspanAttribute() {
      return this.tagName === "td" ? "5" : null;
    },
    adUnitClass: function adUnitClass(placement, showAd) {
      return showAd ? "house-".concat(placement) : "";
    },
    showAd: function showAd(showToGroups, showAfterPost, showAfterTopicListItem, showOnCurrentPage) {
      return showToGroups && (showAfterPost || showAfterTopicListItem) && showOnCurrentPage;
    },
    showAfterPost: function showAfterPost(postNumber, placement) {
      if (!postNumber && placement !== "topic-list-between") {
        return true;
      }

      return this.isNthPost(parseInt(this.site.get("house_creatives.settings.after_nth_post"), 10));
    },
    showAfterTopicListItem: function showAfterTopicListItem(placement) {
      if (placement !== "topic-list-between") {
        return true;
      }

      return this.isNthTopicListItem(parseInt(this.site.get("house_creatives.settings.after_nth_topic"), 10));
    },
    chooseAdHtml: function chooseAdHtml() {
      var houseAds = this.site.get("house_creatives"),
          placement = this.get("placement").replace(/-/g, "_"),
          adNames = this.adsNamesForSlot(placement);

      if (adNames.length > 0) {
        if (!adIndex[placement]) {
          adIndex[placement] = 0;
        }

        var ad = houseAds.creatives[adNames[adIndex[placement]]] || "";
        adIndex[placement] = (adIndex[placement] + 1) % adNames.length;
        return ad;
      } else {
        return "";
      }
    },
    adsNamesForSlot: function adsNamesForSlot(placement) {
      var houseAds = this.site.get("house_creatives");

      if (!houseAds || !houseAds.settings) {
        return [];
      }

      var adsForSlot = houseAds.settings[placement];

      if (Object.keys(houseAds.creatives).length > 0 && !(0, _utils.isBlank)(adsForSlot)) {
        return adsForSlot.split("|");
      } else {
        return [];
      }
    },
    refreshAd: function refreshAd() {
      if (this.get("listLoading")) {
        return;
      }

      this.set("adHtml", this.chooseAdHtml());
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.get("showAd")) {
        return;
      }

      if (this.get("listLoading")) {
        return;
      }

      if (adIndex.topic_list_top === null) {
        // start at a random spot in the ad inventory
        Object.keys(adIndex).forEach(function (placement) {
          var adNames = _this.adsNamesForSlot(placement);

          adIndex[placement] = Math.floor(Math.random() * adNames.length);
        });
      }

      this.refreshAd();
    }
  }, (_applyDecoratedDescriptor(_obj, "colspanAttribute", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "colspanAttribute"), _obj), _applyDecoratedDescriptor(_obj, "adUnitClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "adUnitClass"), _obj), _applyDecoratedDescriptor(_obj, "showAd", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showAd"), _obj), _applyDecoratedDescriptor(_obj, "showAfterPost", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showAfterPost"), _obj), _applyDecoratedDescriptor(_obj, "showAfterTopicListItem", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showAfterTopicListItem"), _obj), _applyDecoratedDescriptor(_obj, "refreshAd", [_dec5], Object.getOwnPropertyDescriptor(_obj, "refreshAd"), _obj)), _obj)));

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-chooser", ["exports", "select-kit/components/multi-select", "@ember/object"], function (_exports, _multiSelect, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      makeArray = _Ember.makeArray;

  var _default = _multiSelect.default.extend({
    classNames: ["house-ads-chooser"],
    filterable: true,
    filterPlaceholder: "admin.adplugin.house_ads.filter_placeholder",
    tokenSeparator: "|",
    allowCreate: false,
    allowAny: false,
    settingValue: "",
    valueAttribute: null,
    nameProperty: null,
    value: (0, _object.computed)("settingValue", function () {
      return this.settingValue.toString().split(this.tokenSeparator).filter(Boolean);
    }),
    // TODO: kept for legacy, remove when Discourse is 2.5
    mutateValues: function mutateValues(values) {
      this.set("settingValue", values.join(this.tokenSeparator));
    },
    computeValues: function computeValues() {
      return this.settingValue.split(this.tokenSeparator).filter(Boolean);
    },
    content: (0, _object.computed)("choices", function () {
      return makeArray(this.choices);
    }),
    actions: {
      onChange: function onChange(value) {
        var settingValue = makeArray(value).join(this.tokenSeparator);
        this.attrs.onChange && this.attrs.onChange(settingValue);
      }
    }
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-list-setting", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/house-ads-setting", "@ember/object/computed"], function (_exports, _houseAdsSetting, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _houseAdsSetting.default.extend({
    classNames: "house-ads-setting house-ads-list-setting",
    adNames: (0, _computed.mapBy)("allAds", "name")
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-setting", ["exports", "@ember/component", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "I18n"], function (_exports, _component, _ajax, _ajaxError, _computed, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: "house-ads-setting",
    adValue: "",
    saving: false,
    savingStatus: "",
    title: (0, _computed.i18n)("name", "admin.adplugin.house_ads.%@.title"),
    help: (0, _computed.i18n)("name", "admin.adplugin.house_ads.%@.description"),
    changed: (0, _computed.propertyNotEqual)("adValue", "value"),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("adValue", this.get("value"));
    },
    actions: {
      save: function save() {
        var _this = this;

        if (!this.get("saving")) {
          this.setProperties({
            saving: true,
            savingStatus: _I18n.default.t("saving")
          });
          (0, _ajax.ajax)("/admin/plugins/pluginad/house_settings/".concat(this.get("name"), ".json"), {
            type: "PUT",
            data: {
              value: this.get("adValue")
            }
          }).then(function () {
            var adSettings = _this.get("adSettings");

            adSettings.set(_this.get("name"), _this.get("adValue"));

            _this.setProperties({
              value: _this.get("adValue"),
              savingStatus: _I18n.default.t("saved")
            });
          }).catch(_ajaxError.popupAjaxError).finally(function () {
            _this.setProperties({
              saving: false,
              savingStatus: ""
            });
          });
        }
      },
      cancel: function cancel() {
        this.set("adValue", this.get("value"));
      }
    }
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/controllers/admin-plugins-house-ads-index", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    adminPluginsHouseAds: (0, _controller.inject)("adminPlugins.houseAds"),
    houseAds: (0, _computed.alias)("adminPluginsHouseAds.model"),
    adSettings: (0, _computed.alias)("adminPluginsHouseAds.houseAdsSettings")
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/controllers/admin-plugins-house-ads-show", ["exports", "I18n", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/computed", "discourse/mixins/buffered-content", "bootbox", "@ember/controller", "@ember/object/computed"], function (_exports, _I18n, _ajax, _ajaxError, _computed, _bufferedContent, _bootbox, _controller, _computed2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend((0, _bufferedContent.bufferedProperty)("model"), {
    adminPluginsHouseAds: (0, _controller.inject)("adminPlugins.houseAds"),
    saving: false,
    savingStatus: "",
    nameDirty: (0, _computed.propertyNotEqual)("buffered.name", "model.name"),
    htmlDirty: (0, _computed.propertyNotEqual)("buffered.html", "model.html"),
    dirty: (0, _computed2.or)("nameDirty", "htmlDirty"),
    disableSave: (0, _computed2.not)("dirty"),
    actions: {
      save: function save() {
        var _this = this;

        if (!this.get("saving")) {
          this.setProperties({
            saving: true,
            savingStatus: _I18n.default.t("saving")
          });
          var data = {},
              buffered = this.get("buffered"),
              newRecord = !buffered.get("id");

          if (!newRecord) {
            data.id = buffered.get("id");
          }

          data.name = buffered.get("name");
          data.html = buffered.get("html");
          (0, _ajax.ajax)(newRecord ? "/admin/plugins/pluginad/house_creatives" : "/admin/plugins/pluginad/house_creatives/".concat(buffered.get("id")), {
            type: newRecord ? "POST" : "PUT",
            data: data
          }).then(function (ajaxData) {
            _this.commitBuffer();

            _this.set("savingStatus", _I18n.default.t("saved"));

            if (newRecord) {
              var model = _this.get("model");

              model.set("id", ajaxData.house_ad.id);

              var houseAds = _this.get("adminPluginsHouseAds.model");

              if (!houseAds.includes(model)) {
                houseAds.pushObject(model);
              }

              _this.transitionToRoute("adminPlugins.houseAds.show", model.get("id"));
            }
          }).catch(_ajaxError.popupAjaxError).finally(function () {
            _this.setProperties({
              saving: false,
              savingStatus: ""
            });
          });
        }
      },
      cancel: function cancel() {
        this.rollbackBuffer();
      },
      destroy: function destroy() {
        var _this2 = this;

        var houseAds = this.get("adminPluginsHouseAds.model");
        var model = this.get("model");

        if (!model.get("id")) {
          this.transitionToRoute("adminPlugins.houseAds.index");
          return;
        }

        (0, _ajax.ajax)("/admin/plugins/pluginad/house_creatives/".concat(model.get("id")), {
          type: "DELETE"
        }).then(function () {
          houseAds.removeObject(model);

          _this2.transitionToRoute("adminPlugins.houseAds.index");
        }).catch(function () {
          return _bootbox.default.alert(_I18n.default.t("generic_error"));
        });
      }
    }
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/controllers/admin-plugins-house-ads", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    loadingAds: true
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/helpers/slot-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNthPost = isNthPost;
  _exports.isNthTopicListItem = isNthTopicListItem;

  function isNthPost(every, currentPostNumber) {
    if (every && every > 0) {
      return currentPostNumber % every === 0;
    } else {
      return false;
    }
  }

  function isNthTopicListItem(every, currentIndexPosition) {
    if (every && every > 0 && currentIndexPosition > 0) {
      return (currentIndexPosition + 1) % every === 0;
    } else {
      return false;
    }
  }
});
define("discourse/plugins/discourse-adplugin/discourse/routes/admin-plugins-house-ads-index", ["exports", "discourse/routes/discourse", "@ember/object"], function (_exports, _discourse, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _discourse.default.extend((_obj = {
    moreSettings: function moreSettings() {
      this.transitionTo("adminSiteSettingsCategory", "ad_plugin");
    }
  }, (_applyDecoratedDescriptor(_obj, "moreSettings", [_object.action], Object.getOwnPropertyDescriptor(_obj, "moreSettings"), _obj)), _obj));

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/routes/admin-plugins-house-ads-show", ["exports", "discourse/routes/discourse", "@ember/object", "I18n"], function (_exports, _discourse, _object, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    model: function model(params) {
      if (params.ad_id === "new") {
        return _object.default.create({
          name: _I18n.default.t("admin.adplugin.house_ads.new_name"),
          html: ""
        });
      } else {
        return this.modelFor("adminPlugins.houseAds").findBy("id", parseInt(params.ad_id, 10));
      }
    }
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-adplugin/discourse/routes/admin-plugins-house-ads", ["exports", "discourse/lib/ajax", "discourse/routes/discourse", "@ember/object"], function (_exports, _ajax, _discourse, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _discourse.default.extend({
    settings: null,
    model: function model() {
      var _this = this;

      return (0, _ajax.ajax)("/admin/plugins/pluginad/house_creatives.json").then(function (data) {
        _this.set("settings", _object.default.create(data.settings));

        return data.house_ads.map(function (ad) {
          return _object.default.create(ad);
        });
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        houseAdsSettings: this.get("settings"),
        loadingAds: false
      });
    }
  });

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/admin/plugins-house-ads-index"] = Ember.HTMLBars.template({"id":null,"block":"[[[6,[39,0],null,[[\"class\"],[\"house-ads-settings content-body\"]],[[\"default\"],[[[[1,\"  \"],[10,0],[12],[1,[28,[35,1],[\"admin.adplugin.house_ads.description\"],null]],[13],[1,\"\\n\\n\"],[41,[33,3,[\"length\"]],[[[1,\"    \"],[10,\"form\"],[14,0,\"form-horizontal\"],[12],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"name\",\"value\",\"allAds\",\"adSettings\"],[\"topic_list_top\",[33,5,[\"topic_list_top\"]],[33,3],[33,5]]]]],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"name\",\"value\",\"allAds\",\"adSettings\"],[\"topic_above_post_stream\",[33,5,[\"topic_above_post_stream\"]],[33,3],[33,5]]]]],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"name\",\"value\",\"allAds\",\"adSettings\"],[\"topic_above_suggested\",[33,5,[\"topic_above_suggested\"]],[33,3],[33,5]]]]],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"name\",\"value\",\"allAds\",\"adSettings\"],[\"post_bottom\",[33,5,[\"post_bottom\"]],[33,3],[33,5]]]]],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"name\",\"value\",\"allAds\",\"adSettings\"],[\"topic_list_between\",[33,5,[\"topic_list_between\"]],[33,3],[33,5]]]]],[1,\"\\n\\n      \"],[1,[28,[35,6],null,[[\"label\",\"icon\",\"class\",\"action\"],[\"admin.adplugin.house_ads.more_settings\",\"cog\",\"btn-default\",[28,[37,7],[\"moreSettings\"],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,2],[12],[1,\"\\n\"],[6,[39,8],null,[[\"route\",\"model\"],[\"adminPlugins.houseAds.show\",\"new\"]],[[\"default\"],[[[[1,\"        \"],[1,[28,[35,1],[\"admin.adplugin.house_ads.get_started\"],null]],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]]],[]]]]]],[],false,[\"d-section\",\"i18n\",\"if\",\"houseAds\",\"house-ads-list-setting\",\"adSettings\",\"d-button\",\"route-action\",\"link-to\"]]","moduleName":"javascripts/discourse/templates/admin/plugins-house-ads-index","isStrictMode":false});
Ember.TEMPLATES["javascripts/admin/plugins-house-ads-show"] = Ember.HTMLBars.template({"id":null,"block":"[[[6,[39,0],null,[[\"class\"],[\"edit-house-ad content-body\"]],[[\"default\"],[[[[1,\"  \"],[10,\"h1\"],[12],[1,[28,[35,1],null,[[\"class\",\"value\"],[\"house-ad-name\",[33,2,[\"name\"]]]]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"content\",\"mode\"],[[33,2,[\"html\"]],\"html\"]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"action\",\"disabled\",\"class\",\"label\"],[[28,[37,5],[[30,0],\"save\"],null],[33,6],\"btn-primary\",\"admin.adplugin.house_ads.save\"]]]],[1,\"\\n\\n\"],[41,[33,8],[[[1,\"      \"],[1,[34,9]],[1,\"\\n\"]],[]],[[[41,[33,10],[[[1,\"        \"],[11,3],[24,6,\"\"],[4,[38,5],[[30,0],\"cancel\"],null],[12],[1,[28,[35,11],[\"cancel\"],null]],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"action\",\"class\",\"label\"],[[28,[37,5],[[30,0],\"destroy\"],null],\"btn-danger delete-button\",\"admin.adplugin.house_ads.delete\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"d-section\",\"text-field\",\"buffered\",\"ace-editor\",\"d-button\",\"action\",\"disableSave\",\"if\",\"saving\",\"savingStatus\",\"dirty\",\"i18n\"]]","moduleName":"javascripts/discourse/templates/admin/plugins-house-ads-show","isStrictMode":false});
Ember.TEMPLATES["javascripts/admin/plugins-house-ads"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,0],[14,0,\"adplugin-mgmt\"],[12],[1,\"\\n  \"],[10,\"h1\"],[12],[1,[28,[35,0],[\"admin.adplugin.house_ads.title\"],null]],[13],[1,\"\\n\"],[41,[33,2,[\"length\"]],[[[1,\"    \"],[10,0],[14,0,\"content-list\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"house-ads-actions\"],[12],[1,\"\\n\"],[6,[39,3],null,[[\"class\",\"route\",\"model\"],[\"btn btn-primary\",\"adminPlugins.houseAds.show\",\"new\"]],[[\"default\"],[[[[1,\"          \"],[1,[28,[35,4],[\"plus\"],null]],[1,\"\\n          \"],[10,1],[12],[1,[28,[35,0],[\"admin.adplugin.house_ads.new\"],null]],[13],[1,\"\\n\"]],[]]]]],[6,[39,3],null,[[\"class\",\"route\"],[\"btn btn-default\",\"adminPlugins.houseAds.index\"]],[[\"default\"],[[[[1,\"          \"],[1,[28,[35,4],[\"cog\"],null]],[1,\"\\n          \"],[10,1],[12],[1,[28,[35,0],[\"admin.adplugin.house_ads.settings\"],null]],[13],[1,\"\\n\"]],[]]]]],[1,\"      \"],[13],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"house-ads-list\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,2]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[14,0,\"house-ads-list-item\"],[12],[1,\"\\n\"],[6,[39,3],null,[[\"route\",\"model\"],[\"adminPlugins.houseAds.show\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"              \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"]],[]]]]],[1,\"          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"ad\"],false,[\"i18n\",\"if\",\"model\",\"link-to\",\"d-icon\",\"each\",\"-track-array\",\"component\",\"-outlet\"]]","moduleName":"javascripts/discourse/templates/admin/plugins-house-ads","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/ad-slot"] = Ember.HTMLBars.template({"id":null,"block":"[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[46,[30,1],null,[[\"placement\",\"refreshOnChange\",\"category\",\"listLoading\",\"postNumber\",\"indexNumber\",\"tagName\"],[[33,4],[33,5],[33,6],[33,7],[33,8],[33,9],[33,10]]],null],[1,\"\\n\"]],[1]],null]],[\"adComponent\"],false,[\"each\",\"-track-array\",\"adComponents\",\"component\",\"placement\",\"refreshOnChange\",\"category\",\"listLoading\",\"postNumber\",\"indexNumber\",\"childTagName\"]]","moduleName":"javascripts/discourse/templates/components/ad-slot","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/adbutler-ad"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[1,\"  \"],[10,0],[15,1,[36,2]],[15,0,[36,3]],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"showAd\",\"divId\",\"className\"]]","moduleName":"javascripts/discourse/templates/components/adbutler-ad","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/amazon-product-links"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[41,[33,2,[\"mobileView\"]],[[[1,\"    \"],[10,0],[14,0,\"amazon-product-links-label\"],[15,5,[36,3]],[12],[10,\"h2\"],[12],[1,[28,[35,4],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n    \"],[10,\"iframe\"],[15,5,[36,5]],[14,\"marginwidth\",\"0\"],[14,\"marginheight\",\"0\"],[14,\"scrolling\",\"no\"],[14,\"frameborder\",\"0\"],[15,\"src\",[36,6]],[15,\"title\",[28,[37,4],[\"adplugin.advertisement_label\"],null]],[12],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"amazon-product-links-label\"],[12],[10,\"h2\"],[12],[1,[28,[35,4],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n    \"],[10,0],[14,0,\"container\"],[14,\"align\",\"center\"],[12],[1,\"\\n      \"],[10,\"iframe\"],[15,5,[36,7]],[14,\"marginwidth\",\"0\"],[14,\"marginheight\",\"0\"],[14,\"scrolling\",\"no\"],[14,\"frameborder\",\"0\"],[15,\"src\",[36,8]],[15,\"title\",[28,[37,4],[\"adplugin.advertisement_label\"],null]],[12],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"if\",\"showAd\",\"site\",\"adTitleStyleMobile\",\"i18n\",\"adWrapperStyleMobile\",\"userInputMobile\",\"adWrapperStyle\",\"userInput\"]]","moduleName":"javascripts/discourse/templates/components/amazon-product-links","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/carbonads-ad"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[1,\"  \"],[10,\"script\"],[15,\"src\",[36,2]],[14,1,\"_carbonads_js\"],[14,\"async\",\"\"],[14,4,\"text/javascript\"],[12],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"showAd\",\"url\"]]","moduleName":"javascripts/discourse/templates/components/carbonads-ad","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/google-adsense"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"google-adsense-label\"],[12],[10,\"h2\"],[12],[1,[28,[35,2],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n  \"],[10,0],[14,0,\"google-adsense-content\"],[15,5,[36,3]],[12],[1,\"\\n    \"],[10,\"ins\"],[14,0,\"adsbygoogle\"],[15,5,[36,4]],[15,\"data-ad-client\",[29,[\"ca-pub-\",[36,5]]]],[15,\"data-ad-slot\",[36,6]],[15,\"data-ad-format\",[36,7]],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"showAd\",\"i18n\",\"adWrapperStyle\",\"adInsStyle\",\"publisher_id\",\"ad_code\",\"autoAdFormat\"]]","moduleName":"javascripts/discourse/templates/components/google-adsense","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/google-dfp-ad"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[41,[33,2,[\"mobileView\"]],[[[1,\"    \"],[10,0],[14,0,\"google-dfp-ad-label\"],[15,5,[36,3]],[12],[10,\"h2\"],[12],[1,[28,[35,4],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n    \"],[10,0],[15,1,[36,5]],[15,5,[36,6]],[14,0,\"dfp-ad-unit\"],[14,\"align\",\"center\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"google-dfp-ad-label\"],[12],[10,\"h2\"],[12],[1,[28,[35,4],[\"adplugin.advertisement_label\"],null]],[13],[13],[1,\"\\n    \"],[10,0],[15,1,[36,5]],[15,5,[36,6]],[14,0,\"dfp-ad-unit\"],[14,\"align\",\"center\"],[12],[13],[1,\"\\n\"]],[]]]],[]],null]],[],false,[\"if\",\"showAd\",\"site\",\"adTitleStyleMobile\",\"i18n\",\"divId\",\"adWrapperStyle\"]]","moduleName":"javascripts/discourse/templates/components/google-dfp-ad","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/house-ad"] = Ember.HTMLBars.template({"id":null,"block":"[[[41,[33,1],[[[1,\"  \"],[1,[28,[35,2],[[33,3]],null]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"showAd\",\"html-safe\",\"adHtml\"]]","moduleName":"javascripts/discourse/templates/components/house-ad","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/house-ads-list-setting"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,\"label\"],[15,\"for\",[36,0]],[12],[1,[34,1]],[13],[1,\"\\n\"],[1,[28,[35,2],null,[[\"settingValue\",\"choices\",\"onChange\"],[[33,3],[33,4],[28,[37,5],[[30,0],[28,[37,6],[[33,3]],null]],null]]]]],[1,\"\\n\"],[10,0],[14,0,\"setting-controls\"],[12],[1,\"\\n\"],[41,[33,8],[[[1,\"    \"],[1,[28,[35,9],null,[[\"class\",\"action\",\"icon\"],[\"ok\",[28,[37,5],[[30,0],\"save\"],null],\"check\"]]]],[1,\"\\n    \"],[1,[28,[35,9],null,[[\"class\",\"action\",\"icon\"],[\"cancel\",[28,[37,5],[[30,0],\"cancel\"],null],\"times\"]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,2],[14,0,\"help\"],[12],[1,[34,10]],[13],[1,\"\\n\"]],[],false,[\"name\",\"title\",\"house-ads-chooser\",\"adValue\",\"adNames\",\"action\",\"mut\",\"if\",\"changed\",\"d-button\",\"help\"]]","moduleName":"javascripts/discourse/templates/components/house-ads-list-setting","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/house-ads-setting"] = Ember.HTMLBars.template({"id":null,"block":"[[[10,\"label\"],[15,\"for\",[36,0]],[12],[1,[34,1]],[13],[1,\"\\n\"],[1,[28,[35,2],null,[[\"value\",\"classNames\"],[[33,3],\"house-ads-text-input\"]]]],[1,\"\\n\"],[10,0],[14,0,\"setting-controls\"],[12],[1,\"\\n\"],[41,[33,5],[[[1,\"    \"],[1,[28,[35,6],null,[[\"class\",\"action\",\"icon\"],[\"ok\",[28,[37,7],[[30,0],\"save\"],null],\"check\"]]]],[1,\"\\n    \"],[1,[28,[35,6],null,[[\"class\",\"action\",\"icon\"],[\"cancel\",[28,[37,7],[[30,0],\"cancel\"],null],\"times\"]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,2],[14,0,\"help\"],[12],[1,[34,8]],[13],[1,\"\\n\"]],[],false,[\"name\",\"title\",\"text-field\",\"adValue\",\"if\",\"changed\",\"d-button\",\"action\",\"help\"]]","moduleName":"javascripts/discourse/templates/components/house-ads-setting","isStrictMode":false});
Ember.TEMPLATES["javascripts/components/post-bottom-ad"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"placement\",\"category\",\"postNumber\"],[\"post-bottom\",[33,1,[\"topic\",\"category\",\"slug\"]],[33,1,[\"post_number\"]]]]]],[1,\"\\n\"]],[],false,[\"ad-slot\",\"model\"]]","moduleName":"javascripts/discourse/templates/components/post-bottom-ad","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/after-topic-list-item/discourse-adplugin"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"placement\",\"refreshOnChange\",\"category\",\"listLoading\",\"indexNumber\",\"childTagName\"],[\"topic-list-between\",[33,1],[33,2,[\"slug\"]],[33,1],[33,3],\"td\"]]]],[1,\"\\n\"]],[],false,[\"ad-slot\",\"listLoading\",\"category\",\"index\"]]","moduleName":"javascripts/discourse/templates/connectors/after-topic-list-item/discourse-adplugin","isStrictMode":false});
define("discourse/plugins/discourse-adplugin/discourse/templates/connectors/after-topic-list-item/discourse-adplugin", ["exports", "discourse/plugins/discourse-adplugin/discourse/components/ad-slot"], function (_exports, _adSlot) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender: function shouldRender(args, component) {
      return args.index && (0, _adSlot.slotContenders)(component.site, component.siteSettings, "topic-list-between", args.index).length > 0;
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/connectors/discovery-list-container-top/discourse-adplugin"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"placement\",\"refreshOnChange\",\"category\",\"listLoading\"],[\"topic-list-top\",[33,1],[33,2,[\"slug\"]],[33,1]]]]],[1,\"\\n\"]],[],false,[\"ad-slot\",\"listLoading\",\"category\"]]","moduleName":"javascripts/discourse/templates/connectors/discovery-list-container-top/discourse-adplugin","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/post-bottom/discourse-adplugin"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"model\"],[[30,0]]]]],[1,\"\\n\"]],[],false,[\"post-bottom-ad\"]]","moduleName":"javascripts/discourse/templates/connectors/post-bottom/discourse-adplugin","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/topic-above-post-stream/discourse-adplugin"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"placement\",\"refreshOnChange\",\"category\"],[\"topic-above-post-stream\",[33,1,[\"id\"]],[33,1,[\"category\",\"slug\"]]]]]],[1,\"\\n\"]],[],false,[\"ad-slot\",\"model\"]]","moduleName":"javascripts/discourse/templates/connectors/topic-above-post-stream/discourse-adplugin","isStrictMode":false});
Ember.TEMPLATES["javascripts/connectors/topic-above-suggested/discourse-adplugin"] = Ember.HTMLBars.template({"id":null,"block":"[[[1,[28,[35,0],null,[[\"placement\",\"refreshOnChange\",\"category\"],[\"topic-above-suggested\",[33,1,[\"id\"]],[33,1,[\"category\",\"slug\"]]]]]],[1,\"\\n\"]],[],false,[\"ad-slot\",\"model\"]]","moduleName":"javascripts/discourse/templates/connectors/topic-above-suggested/discourse-adplugin","isStrictMode":false});
define("discourse/plugins/discourse-adplugin/initializers/initialize-ad-plugin", ["exports", "discourse/lib/plugin-api", "discourse/models/site"], function (_exports, _pluginApi, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "initialize-ad-plugin",
    initialize: function initialize(container) {
      (0, _pluginApi.withPluginApi)("0.1", function (api) {
        api.decorateWidget("post:after", function (dec) {
          if (dec.canConnectComponent) {
            if (!dec.attrs.cloaked) {
              return dec.connect({
                component: "post-bottom-ad",
                context: "model"
              });
            }
          } else {
            // Old way for backwards compatibility
            return dec.connect({
              templateName: "connectors/post-bottom/discourse-adplugin",
              context: "model"
            });
          }
        });
      });
      var messageBus = container.lookup("message-bus:main");

      if (!messageBus) {
        return;
      }

      messageBus.subscribe("/site/house-creatives", function (houseAdsSettings) {
        _site.default.currentProp("house_creatives", houseAdsSettings);
      });
    }
  };
  _exports.default = _default;
});

